<template>
    <label class="checkbox" :class="{ 'checkbox--switch': props.displayAsSwitch, 'checkbox--regular': !props.displayAsSwitch, 'checkbox--checked': props.modelValue }">
        <div class="checkbox__wrapper"><input v-model="props.modelValue" type="checkbox" class="checkbox__control" :class="[{ 'cursor-pointer': !props.disabled, 'cursor-not-allowed': props.disabled }, props.innerClass]" :id="props.id" :checked="props.checked" :disabled="props.disabled" @change="checkChanged" /></div>
        <span class="checkbox__label">{{ props.label }}<slot></slot></span>
    </label>
</template>
<script setup>

const props = defineProps({
    id: String,
    label: String,
    modelValue: Boolean,
    disabled: Boolean,
    displayAsSwitch: Boolean,
    innerClass: String
});

const emit = defineEmits(['update:modelValue']);

const checkChanged = ($event) => {
    emit('update:modelValue', $event.target.checked);
}

</script>
<style lang="postcss">
.checkbox {
    @apply inline-flex leading-[1.21em] text-left gap-[1em];

    &__control {
        position: relative;
        width: 0;
        height: 0;
        margin-left: 0;
        vertical-align: top;
        outline: 0;
        -moz-appearance: initial;
    }

    &--regular {

        .checkbox__wrapper {
            width: 1.2em;
            flex: none;
        }

        .checkbox__control::before {
            position: absolute;
            content: "";
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            vertical-align: middle;
            font-size: 1em;
            width: 1.2em;
            height: 1.2em;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            overflow: hidden;
            @apply border-2 border-neutral-200 bg-neutral-50;
        }

        .checkbox__control::after {
            position: absolute;
            content: '';
            font-style: normal;
            font-weight: normal;
            speak: never;
            font-variant: normal;
            text-transform: none;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            transform: rotate(45deg);
            width: 0.3em;
            height: 0.6em;
            margin-top: 0.2em;
            margin-left: 0.45em;
            border-bottom-width: 2px;
            border-right-width: 2px;
            opacity: 0;
            @apply border-neutral-50 transition-all duration-300 ease-in-out;
        }

        .checkbox__control:checked::before {
            font-style: normal;
            font-weight: normal;
            speak: never;
            font-variant: normal;
            text-transform: none;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "";
            @apply bg-primary-500 border-2 border-primary-500;
        }

        .checkbox__control:checked::after {
            opacity: 1;
        }
    }

    &--switch {

        .checkbox__wrapper {
            width: 2.5em;
            height: 1.75em;
        }

        .checkbox__control::before {
            position: absolute;
            content: "";
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            vertical-align: middle;
            font-size: 1em;
            width: 2.75em;
            height: 1.75em;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            overflow: hidden;
            border-radius: 1em;
            @apply border-2 border-neutral-400 bg-neutral-100;
        }

        .checkbox__control::after {
            position: absolute;
            content: "";
            left: 0.45em;
            top: 0.45em;
            width: 0.85em;
            height: 0.85em;
            border-radius: 50%;
            @apply bg-neutral-400 transition-all;
        }

        .checkbox__control:checked::before {
            font-style: normal;
            font-weight: normal;
            speak: never;
            font-variant: normal;
            text-transform: none;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "";
            @apply bg-primary-500 border-2 border-primary-500;
        }

        .checkbox__control:checked::after {
            left: 1.25em;
            top: 0.26em;
            width: 1.2em;
            height: 1.2em;
            @apply bg-neutral-50;
        }

        .checkbox__label {
            display: inline-block;
            padding-top: 0.2em;
        }

        &.checkbox--checked .checkbox__label {
            @apply font-bold text-primary-500;
        }
    }
}
</style>